import { HttpClient } from '@angular/common/http';
import { HostListener } from '@angular/core';
import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';
import { PrintService } from './print/printservice';

@Component({
    selector: 'my-app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
})
export class AppComponent {
    
  //Params
  doctype:any=''
  trxid:number = 0;
  printtype:any=''

  //Array Coll
  templatetypeColl:any=[]
  printColl:any=[]
  printdetailsColl:any=[]

  //Get valuesBO
  headerBO:any={}
  printBO:any={}
  summaryBO:any={}
  printtemplateid:any=null


  sdata:any=null

    constructor(private route: ActivatedRoute,private http: HttpClient,private appService: PrintService) {
        this.route.queryParams.subscribe((params: any) => {
         console.log(params)
         if(params.doctype){
            this.doctype = params.doctype
           }
           if(params.trxid){
            this.trxid = params.trxid
           }
           if(params.printtype){
            this.printtype = params.printtype
           }


           if(this.trxid > 0){
            this.fngetPrintInvoice()
          }
          
        });
       }

    ngOnInit(): void {
        if(this.trxid > 0){
            this.fngetPrintInvoice()
          }else{
            console.log("ID ZERO")
          }

          
    }

    fngetPrintInvoice() {
        // alert('d')
         var data = {
           'trxid': this.trxid,
           'doctype': this.doctype,
           'printtype':this.printtype
         }
     
         this.appService.getPrintInvoice(data)
           .subscribe(
             async (res: any) => {
               if (res.status === 200) {
                 var result = res.result;
                 if (result[0].length > 0) {
                   this.headerBO = result[0][0]
                   this.printtemplateid=this.headerBO.printtemplateid
                 }
                 if (result.length > 1) {
                   this.templatetypeColl = result[1];             
                 }
                 if (result.length > 2) {
                   this.printColl = result[2];
                   if (this.printColl.length > 0) {
                     this.printBO = this.printColl[0]
                   }
                 }
                 if (result.length > 3) {
                   this.printdetailsColl = result[3];
                 }
                 if (result.length > 4) {
                   if (result[4].length > 0) {
                     this.summaryBO = result[4][0]
                   }
                 }
               }
               else if (res.status === 400) {
                 return;
               }
               var sdata:any={
                 summaryBO: this.summaryBO,
                 headerBO: this.headerBO,
                 printBO: this.printBO,
                 printColl: this.printColl,
                 printdetailsColl: this.printdetailsColl,
               }
    
               this.sdata = sdata
               
              //  setTimeout(() => {
              //    this.fnSendMessage(sdata)
              //  }, 1000);
             },
             (err) => {
             })
       }
    
}
